<template>
  <div class="company-add-edit-box">
    <van-nav-bar :title="title" left-text="返回" left-arrow @click-left="onClickLeft" />
    <van-form class="form" @submit="onSubmit">
      <div class="filed-box">
        <van-field
          name="uploader"
          required
          label="上传企业头像"
          :rules="[{ required: true, message: '请上传企业头像' }]"
        >
          <template #input>
            <van-uploader
              v-model="fileList"
              :max-size="1024 * 1024"
              accept="image/jpg, image/png"
              :after-read="afterRead"
              :max-count="1"
              @oversize="onOversize"
            />
          </template>
        </van-field>
      </div>
      <div class="filed-box">
        <van-field
          v-model="companyForm.nameCn"
          name="公司名称"
          label="公司名称"
          required
          placeholder="请输入公司名称"
          :rules="[{ required: true }]"
        />
      </div>
      <div class="filed-box">
        <van-field
          v-model="companyForm.nameEn"
          name="公司英文名称"
          label="公司英文名称"
          required
          placeholder="请输入公司英文名称"
          :rules="[{ required: true }]"
        />
      </div>
      <div class="filed-box">
        <van-field
          v-model="companyForm.shortName"
          name="公司简称"
          label="公司简称"
          required
          placeholder="请输入公司简称"
          :rules="[{ required: true }]"
        />
      </div>
      <div class="filed-box">
        <van-field
          v-model="companyForm.companyTypeName"
          name="选择企业性质"
          label="选择企业性质"
          required
          readonly
          placeholder="请选择企业性质"
          :rules="[{ required: true }]"
          @click="companyTypePicker = true"
        >
          <template #button>
            <van-icon v-if="companyTypePicker" name="arrow-up" />
            <van-icon v-else name="arrow-down" />
          </template>
        </van-field>
        <van-popup v-model="companyTypePicker" round position="bottom">
          <van-picker
            show-toolbar
            :columns="dictionaries.companyType"
            @cancel="companyTypePicker = false"
            @confirm="companyTypeConfirm"
          >
            <template #option="item">
              <p style="text-align: center">{{ item.name }}</p>
            </template>
          </van-picker>
        </van-popup>
      </div>
      <div class="filed-box">
        <van-field
          v-model="companyForm.industryTypeName"
          name="选择企业行业"
          label="选择企业行业"
          required
          readonly
          placeholder="请选择企业行业"
          :rules="[{ required: true }]"
          @click="industryTypePicker = true"
        >
          <template #button>
            <van-icon v-if="industryTypePicker" name="arrow-up" />
            <van-icon v-else name="arrow-down" />
          </template>
        </van-field>
        <van-popup v-model="industryTypePicker" round position="bottom">
          <van-picker
            show-toolbar
            :columns="dictionaries.industryType"
            @cancel="industryTypePicker = false"
            @confirm="industryTypeConfirm"
          >
            <template #option="item">
              <p style="text-align: center">{{ item.name }}</p>
            </template>
          </van-picker>
        </van-popup>
      </div>
      <div class="filed-box">
        <van-field
          v-model="companyForm.scaleTypeName"
          name="公司规模"
          label="公司规模"
          required
          readonly
          placeholder="请选择公司规模"
          :rules="[{ required: true }]"
          @click="scalePicker = true"
        >
          <template #button>
            <van-icon v-if="scalePicker" name="arrow-up" />
            <van-icon v-else name="arrow-down" />
          </template>
        </van-field>
        <van-popup v-model="scalePicker" round position="bottom">
          <van-picker
            show-toolbar
            :columns="dictionaries.scale"
            @cancel="scalePicker = false"
            @confirm="scaleConfirm"
          >
            <template #option="item">
              <p style="text-align: center">{{ item.name }}</p>
            </template>
          </van-picker>
        </van-popup>
      </div>
      <div class="filed-box">
        <van-field
          v-model="companyForm.financingTypeName"
          name="融资情况"
          label="融资情况"
          required
          readonly
          placeholder="请选择融资情况"
          :rules="[{ required: true }]"
          @click="financingTypePicker = true"
        >
          <template #button>
            <van-icon v-if="financingTypePicker" name="arrow-up" />
            <van-icon v-else name="arrow-down" />
          </template>
        </van-field>
        <van-popup v-model="financingTypePicker" round position="bottom">
          <van-picker
            show-toolbar
            :columns="dictionaries.financingType"
            @cancel="financingTypePicker = false"
            @confirm="financingTypeConfirm"
          >
            <template #option="item">
              <p style="text-align: center">{{ item.name }}</p>
            </template>
          </van-picker>
        </van-popup>
      </div>
      <div class="filed-box editors-box">
        <van-field
          v-model="companyForm.description"
          name="公司介绍"
          label="公司介绍"
          disabled
          required
          readonly
          :rules="[{ required: true, message: '公司介绍' }]"
        />
        <quill-editors
          @ChangeText="
            (text) => {
              companyForm.description = text;
            }
          "
          :showData="companyForm.description"
          :title="true"
        />
      </div>
      <div class="filed-box editors-box">
        <van-field
          v-model="companyForm.benefit"
          name="公司福利"
          label="公司福利"
          disabled
          required
          readonly
          :rules="[{ required: true, message: '公司福利' }]"
        />
        <quill-editors
          @ChangeText="
            (text) => {
              companyForm.benefit = text;
            }
          "
          :showData="companyForm.benefit"
          :title="true"
        />
      </div>
      <div class="filed-box editors-box">
        <van-field
          v-model="companyForm.interviewFlow"
          name="面试流程"
          label="面试流程"
          disabled
          required
          readonly
          :rules="[{ required: true, message: '面试流程' }]"
        />
        <quill-editors
          @ChangeText="
            (text) => {
              companyForm.interviewFlow = text;
            }
          "
          :showData="companyForm.interviewFlow"
          :title="true"
        />
      </div>
      <div class="filed-box">
        <van-field
          readonly
          clickable
          name="area"
          v-model="areaName"
          label="公司地区"
          required
          placeholder="请选择公司地区"
          :rules="[{ required: true }]"
          @click="showArea = true"
        >
          <template #button>
            <van-icon v-if="showArea" name="arrow-up" />
            <van-icon v-else name="arrow-down" />
          </template>
        </van-field>
        <van-popup v-model="showArea" position="bottom">
          <van-area :area-list="areaList" @confirm="areaConfirm" @cancel="showArea = false" />
          <!-- <van-picker
            show-toolbar
            :columns="areaList"
            :loading="loading"
            @change="areaChange"
            @cancel="showArea = false"
            @confirm="areaConfirm"
          >
            <template #option="item">
              <p style="text-align: center">{{ item.name }}</p>
            </template>
          </van-picker>-->
        </van-popup>
      </div>
      <div class="filed-box">
        <van-field
          v-model="companyForm.address"
          name="门牌号"
          label="门牌号"
          required
          placeholder="请填写门牌号"
          :rules="[{ required: true }]"
        />
      </div>

      <div class="btn-box">
        <van-button round block type="info">提交</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import areaList from '../../utils/area.json';

export default {
  name: 'CompanyAddOrEdit',
  data() {
    return {
      title: '新增企业信息',
      dictionaries: {},
      needDictionaries: [
        'companyType',
        'industryType',
        'scale',
        'financingType',
      ],
      fileList: [],
      companyTypeName: '',
      companyTypePicker: false,
      industryTypeName: '',
      industryTypePicker: false,
      scaleName: '',
      scalePicker: false,
      financingTypeName: '',
      financingTypePicker: false,
      showArea: false,
      areaName: '',
      areaList: Object.freeze(areaList), // 省市数据
      loading: false,
      companyForm: {
        id: null,
        avatarUrl: null, //头像图片地址   默认:'http://pankh26y4.bkt.clouddn.com/11/20180902/H2160037764.png'
        nameCn: null, //公司中文名
        nameEn: null, //公司英文名
        shortName: null, //公司简称
        companyType: null, //公司类型
        companyTypeName: '', //行业名称
        industryType: null, //行业类型
        industryTypeName: '', //行业名称
        scaleTypeName: '', //行业名称
        scaleType: null, //公司规模
        financingType: null, //融资情况
        financingTypeName: '', //融资名称
        description: null, //公司描述
        interviewFlow: null, //面试流程
        benefit: null, //公司福利
        areaId: null, //地址
        address: null, //详细地址
        longitude: null, // 经度 （需要调用地图API, 根据地址反查）
        latitude: null, //纬度
        startup: false, //是否创业公司
      },
    };
  },
  async mounted() {
    let back = await this.$http.get('/selfhelpOfficialAccounts/relation');
    if (back.code === 200) {
      localStorage.setItem('userInfo', JSON.stringify(back.data));
      this.userInfo = back.data;
      let { query } = this.$route;
      if (query.id) {
        this.title = '编辑企业信息';
        Object.assign(this.companyForm, this.userInfo.companyVO);
        this.fileList = [{ url: this.userInfo.companyVO.avatarUrl }];
        let areaId = this.userInfo.companyVO.areaId.toString();
        this.areaName =
          areaList.province_list[areaId.slice(0, 2) + '0000'] +
          '/' +
          areaList.city_list[areaId.slice(0, 4) + '00'] +
          '/' +
          areaList.county_list[areaId.slice(0, 6)];
      }
      if (query.name) {
        this.companyForm.nameCn = query.name
      }
    }
    let dictionaries = localStorage.getItem('dictionaries');
    if (dictionaries) {
      dictionaries = JSON.parse(dictionaries);
      dictionaries.forEach((el) => {
        if (this.needDictionaries.includes(el.type)) {
          this.dictionaries[el.type] = el.children;
        }
      });
    }
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onOversize(file) {
      console.log(file);
      this.$toast('文件大小不能超过 1MB');
    },
    // bae64转文件对象
    dataURLtoFileFun(dataurl, filename) {
      // 将base64转换为文件，dataurl为base64字符串，filename为文件名（必须带后缀名，如.jpg,.png）
      const arr = dataurl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    afterRead() {
      const fromData = new FormData();
      fromData.append(
        'files',
        this.dataURLtoFileFun(
          this.fileList[0].content,
          this.fileList[0].file.name
        )
      );
      this.$http
        .postUpload(
          '/selfhelpOfficialAccounts/relation/upload/APPROVE',
          fromData
        )
        .then((res) => {
          if (res.code === 200) {
            this.companyForm.avatarUrl = res.data;
          }
        });
    },
    companyTypeConfirm(data) {
      this.companyForm.companyTypeName = data.name;
      this.companyForm.companyType = data.code; //公司类型
      this.companyTypePicker = false;
    },
    industryTypeConfirm(data) {
      this.companyForm.industryTypeName = data.name;
      this.companyForm.industryType = data.code; //行业类型
      this.industryTypePicker = false;
    },
    scaleConfirm(data) {
      this.companyForm.scaleTypeName = data.name;
      this.companyForm.scaleType = data.code; //公司规模
      this.scalePicker = false;
    },
    financingTypeConfirm(data) {
      this.companyForm.financingTypeName = data.name;
      this.companyForm.financingType = data.code; //融资情况
      this.financingTypePicker = false;
    },
    async getProvinces() {
      let res = await this.$http.get(
        '/selfhelpOfficialAccounts/area/provinces'
      );
      return res;
    },
    async getAreaChildren(id) {
      let res = await this.$http.get(
        '/selfhelpOfficialAccounts/area/parent/' + id
      );
      return res;
    },
    async areaPopupOpen() {
      if (this.areaList[0].values.length === 0) {
        this.loading = true;
        let res = await this.getProvinces();
        if (res.code === 200) {
          this.areaList[0].values = res.data;
          let res2 = await this.getAreaChildren(res.data[0].id);
          this.areaList[1].values = res2.data[0].children;
          if (res2.code === 200) {
            console.log(res2.data[0]);
            let res3 = await this.getAreaChildren(
              res2.data[0].children[0].id
            );
            if (res3.code === 200) {
              this.areaList[2].values = res3.data[0].children;
              this.loading = false;
              this.showArea = true;
            }
          }
        }
      } else {
        this.showArea = true;
      }
    },
    async areaChange(picker, values) {
      console.log(picker, values);
      if (this.areaList[0].values.length === 0) {
        let res = await this.getProvinces();
        if (res.code === 200) {
          console.log(res);
        }
      }
      if (this.areaList[1].values.length === 0) {
        let res = await this.getAreaChildren();
        if (res.code === 200) {
          console.log(res);
        }
      }
    },
    areaConfirm(values) {
      this.areaName = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join('/');
      this.companyForm.areaId = values
        .filter((item) => !!item)
        .map((item) => item.code)
        .slice(-1)[0];
      this.showArea = false;
    },
    onSubmit() {
      if (!this.companyForm.id) {
        this.$http
          .post('/selfhelpOfficialAccounts/companies', this.companyForm)
          .then((res) => {
            if (res.code === 200) {
              this.$toast('新增企业成功');
              this.onClickLeft();
            }
          });
      } else {
        this.$dialog
          .confirm({
            message: `确认要修改企业信息么?`,
          })
          .then(() => {
            this.$http
              .put(
                '/selfhelpOfficialAccounts/companies/' + this.companyForm.id,
                this.companyForm
              )
              .then((res) => {
                if (res.code === 200) {
                  this.$toast('修改企业信息成功');
                  this.onClickLeft();
                }
              });
          });

      }
    },
  },
};
</script>

<style lang="scss" scoped>
.company-add-edit-box {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  .form {
    flex: 1;
    padding: 0 15px;
    overflow-y: auto;
  }

  .list-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 8px 5px 0;
    > p {
      font-size: 15px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
  }
  .filed-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ebedf0;
    > .van-cell {
      flex: 1;
    }
    > .van-cell::after {
      border-bottom: none;
    }
    img {
      width: 77px;
      height: 23px;
    }
    ::v-deep .van-field__label span {
      font-size: 15px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }

    ::v-deep .van-field--disabled .van-field__label {
      color: #646566;
    }
    ::v-deep .van-field__value input {
      text-align: right;
    }
    ::v-deep .van-field__control--custom {
      justify-content: right;
    }
    ::v-deep .van-field--min-height .van-field__control {
      text-align: right;
    }
  }
  .editors-box {
    display: initial;
    ::v-deep .van-field__body input {
      display: none;
    }
  }
  .van-search .van-cell {
    padding: 5px 8px 5px 0;
    ::v-deep .van-field__value input {
      text-align: initial;
    }
  }
  .btn-box {
    margin-top: 30px;
  }
  ::v-deep .van-field__error-message {
    text-align: right;
    font-size: 14px;
  }
}
</style>
